import LoadingSpinner from "../utils/LoadingSpinner";

const Loader = ({ isLoading, children }) => {
    if (isLoading) {
        return <LoadingSpinner />;
    }

    return children;
};

export default Loader;
