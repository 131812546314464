import { useState, useEffect, useCallback } from "react";
import apiClient from "../api/apiClient";

const useConfig = (mode) => {
    const [isOpen, setisOpen] = useState(false);
    const [isLoading, setisLoading] = useState(true);
    const [config, setConfig] = useState({});

    const fetchData = useCallback(() => {
        apiClient
            .get("config/")
            .then((res) => {
                if (mode === "ambassador") {
                    setisOpen(res.data.isAmbassadorRegOpen);
                } else if (mode === "officer") {
                    setisOpen(res.data.isOfficerRegOpen);
                } else if (mode === "team") {
                    setisOpen(res.data.isTeamRegOpen);
                }
                setConfig({
                    foodFee: res.data.foodFee,
                    accommodationFee: res.data.accommodationFee,
                    isFoodRegOpen: res.data.isFoodRegOpen,
                    isAccommodationRegOpen: res.data.isAccommodationRegOpen,
                });
            })
            .finally(() => setisLoading(false));
    }, [mode]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return {
        isOpen,
        config,
        isLoading,
    };
};

export default useConfig;
