import Loader from "../Loader";
import Title from "../Title/Title";
import apiClient from "../../api/apiClient";
import { useQuery } from "react-query";
import Error from "../Error";

const MediaPartners = () => {
    const {
        data: MediaPartners,
        error,
        isLoading,
    } = useQuery(
        "mediaPartners",
        async () => {
            const response = await apiClient.get("/media-partners/");
            return response.data;
        },
        {
            cacheTime: 300000, // 5 minutes
        }
    );

    if (error) {
        return <Error />;
    }

    if (MediaPartners) {
        return (
            <section className="md:mt-20 mediaPartners w-full mt-10">
                <Title text="Media Partners" />
                <Loader isLoading={isLoading}>
                    <div className="md:flex-row md:justify-between md:w-3/4 flex flex-col flex-wrap justify-center gap-4 px-4 mx-auto">
                        {MediaPartners.map((media) => (
                            <a
                                key={media.id}
                                href={media.link}
                                target="_blank"
                                rel="noreferrer"
                                className={`flex flex-col items-center justify-center p-4 cursor-pointer`}
                            >
                                <img
                                    src={media.logo}
                                    alt={media.title}
                                    title={media.title}
                                    className="p-0 max-w-[300px] my-auto"
                                />
                            </a>
                        ))}
                    </div>
                </Loader>
            </section>
        );
    }
};

export default MediaPartners;
