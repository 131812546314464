import { useState } from "react";
import { toast } from "react-toastify";
import apiClient from "../../api/apiClient";
import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import Layout from "../../components/Layouts/Layout";
import Spacer from "../../components/Spacer/Spacer";
import Title from "../../components/Title/Title";
import useConfig from "../../utils/useConfig.jsx";
import { useQuery } from "react-query";
import Loader from "../../components/Loader.jsx";

const OfficerRegistration = () => {
    const [form, setForm] = useState({
        name: "",
        email: "",
        phone: "",
        discipline: "",
        batch: "",
        roll_no: "",
        departments: [],
    });
    const [loading, setLoading] = useState(false);
    const { isOpen, isLoading } = useConfig("officer");

    const { data: Departments } = useQuery(
        ["departments"],
        async () => {
            const result = await apiClient.get("departments/");
            return result.data;
        },
        {
            cacheTime: 3600000, // 1 hour
        }
    );

    const { data: Batches } = useQuery(
        ["batches"],
        async () => {
            const result = await apiClient.get("batches/");
            return result.data;
        },
        {
            cacheTime: 3600000, // 1 hour
        }
    );

    const { data: Disciplines } = useQuery(
        ["disciplines"],
        async () => {
            const result = await apiClient.get("disciplines/");
            return result.data;
        },
        {
            cacheTime: 3600000, // 1 hour
        }
    );

    const handleSubmit = (e) => {
        e.preventDefault();

        if (form.phone.length !== 11) {
            toast.error(
                "Please enter phone numnber in the format as requested!"
            );
            return;
        }

        if (
            !(
                form.name &&
                form.email &&
                form.phone &&
                form.batch &&
                form.discipline &&
                form.roll_no &&
                form.departments.length !== 0
            )
        ) {
            toast.error("Please fill all the fields");
            return;
        }
        setLoading(true);
        apiClient
            .post("/registration/volunteer/", form)
            .then((res) => {
                toast.success(
                    "Registration Successful, Our team will get in contact with you soon!"
                );
                setForm({
                    name: "",
                    email: "",
                    phone: "",
                    batch: "",
                    discipline: "",
                    roll_no: "",
                    departments: [],
                });
            })
            .catch((err) => {
                toast.error("Registration Failed!");
            })
            .finally(() => setLoading(false));
    };

    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };

    return (
        <Layout size={6} title="Officer Registration">
            <Title text="Officer Registration" />

            <Loader isLoading={isLoading}>
                {!isLoading && isOpen ? (
                    <form
                        className="flex flex-col items-center justify-center w-full px-6"
                        onSubmit={handleSubmit}
                    >
                        <section className="md:w-4/6 inputs w-full">
                            <Input
                                text="Full Name"
                                value={form.name}
                                name="name"
                                placeholder="John Doe"
                                onChange={handleChange}
                                required
                            />

                            <Input
                                text="Email Address"
                                type="email"
                                value={form.email}
                                name="email"
                                placeholder="john@mail.com"
                                onChange={handleChange}
                                required
                            />

                            <Input
                                text="Phone Number"
                                value={form.phone}
                                name="phone"
                                placeholder="03001234567"
                                pattern="[0-9]{11}"
                                onChange={handleChange}
                                required
                            />
                            <Input
                                text="Discipline"
                                type="select"
                                value={form.discipline}
                                name="discipline"
                                list={Disciplines || []}
                                onChange={handleChange}
                                required
                            />

                            <div className="md:grid-cols-2 md:gap-6 grid">
                                <Input
                                    text="Batch"
                                    type="select"
                                    value={form.batch}
                                    name="batch"
                                    list={Batches || []}
                                    onChange={handleChange}
                                    required
                                />

                                <Input
                                    text="Roll Number"
                                    value={form.roll_no}
                                    name="roll_no"
                                    placeholder="20L-1234"
                                    pattern="[0-9]{2}[A-Za-z]{1}-[0-9]{4}"
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </section>

                        <div className="my-6">
                            <h2 className="text-primary pb-4 text-2xl font-bold text-center">
                                Select Departments
                            </h2>
                        </div>
                        <div className="mb-6">
                            <ul className="md:grid-cols-3 deps grid gap-4">
                                {!!Departments &&
                                    Departments.map((department) => (
                                        <div
                                            className="flex w-full select-none"
                                            key={department.id}
                                        >
                                            <li className="w-full">
                                                <input
                                                    type="checkbox"
                                                    id={department.id}
                                                    name={department.name}
                                                    value={department.id}
                                                    className="peer hidden"
                                                    checked={form.departments.includes(
                                                        department.id
                                                    )}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setForm({
                                                                ...form,
                                                                departments: [
                                                                    ...form.departments,
                                                                    +e.target
                                                                        .value,
                                                                ],
                                                            });
                                                        } else {
                                                            setForm({
                                                                ...form,
                                                                departments:
                                                                    form.departments.filter(
                                                                        (dep) =>
                                                                            dep !==
                                                                            +e
                                                                                .target
                                                                                .value
                                                                    ),
                                                            });
                                                        }
                                                    }}
                                                />
                                                <label
                                                    htmlFor={department.id}
                                                    className="px-6 py-2 select-none overflow-hidden text-ellipsis whitespace-nowrap w-full block text-center bg-white text-gray-500 rounded-full border-2 border-logo-blue cursor-pointer peer-checked:!bg-logo-blue peer-checked:text-white hover:text-gray-600  hover:bg-gray-50"
                                                >
                                                    {department.name}
                                                </label>
                                            </li>
                                        </div>
                                    ))}
                            </ul>
                        </div>
                        <Button loading={loading} text="Register" />
                    </form>
                ) : (
                    <>
                        <h1 className="md:text-2xl mt-5 text-xl text-center text-red-500">
                            Registration is Closed!
                        </h1>
                        <Spacer height={300} />
                    </>
                )}
            </Loader>
        </Layout>
    );
};

export default OfficerRegistration;
