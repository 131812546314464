import FeatureCard from "../../components/FeatureCard/FeatureCard";
import { imagesArr } from "../../assets";

let formatter = new Intl.NumberFormat("ur-PK", {
    style: "currency",
    currency: "PKR",
    minimumFractionDigits: 0,
}).format;

const CompetitionInfo = ({ selected }) => {
    return (
        <div className="md:px-6 flex flex-wrap justify-center gap-12 px-6 mb-6">
            <FeatureCard
                text="Registration Fee"
                value={formatter(selected.fees)}
                bg="bg-blue-500 border-blue-300"
                svg={imagesArr.Dollar}
            />
            <FeatureCard
                text="Max Members"
                value={selected.max_team_size}
                bg="bg-logo-indigo border-logo-indigo"
                svg={imagesArr.Person}
            />
        </div>
    );
};

export default CompetitionInfo;
