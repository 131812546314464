import ImageBox from "../../components/Image/ImageBox";
import Layout from "../../components/Layouts/Layout";
import Title from "../../components/Title/Title";
import apiClient from "../../api/apiClient";
import { useQuery } from "react-query";
import Loader from "../../components/Loader";
import Spacer from "../../components/Spacer/Spacer";

const Ambassadors = () => {
  const {
    data: Ambassadors,
    isLoading,
    isFetched,
    isError,
  } = useQuery(
    ["ambassadors"],
    async () => {
      const result = await apiClient.get("/ambassadors/");
      return result.data;
    },
    {
      cacheTime: 3600000, // 1 hour
    }
  );

  return (
    <Layout size={7} title="Ambassadors">
      <Title text="Ambassadors" />
      <div className="flex flex-wrap justify-center gap-6">
        <Loader isLoading={isLoading}>
          {isError || (isFetched && Ambassadors?.length === 0) ? (
            <div className="flex flex-col items-center justify-center w-full h-full">
              <h1 className="text-2xl font-bold text-center text-gray-500">
                No Ambassadors Found!
              </h1>
              <Spacer height={400} />
            </div>
          ) : (
            !!Ambassadors &&
            Ambassadors.map((member) => (
              <ImageBox role="ambassador" member={member} key={member.code} />
            ))
          )}
        </Loader>
      </div>
    </Layout>
  );
};

export default Ambassadors;
