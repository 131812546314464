import FadeSlideIn from "../Animations/FadeSlideIn";

const SponsorBox = ({ sponsorList, level }) => {
    return (
        <div>
            <h2 className="text-primary mt-10 text-3xl font-bold text-center">
                {level}
            </h2>
            <div
                className={`flex flex-wrap justify-evenly gap-1 p-6 mt-6 shadow-xl md:gap-3`}
            >
                {sponsorList.map((sponsor) => (
                    <a
                        key={sponsor.id}
                        href={sponsor.link}
                        target="_blank"
                        rel="noreferrer"
                        className="flex flex-col items-center justify-center p-4"
                    >
                        <FadeSlideIn fullHeight>
                            <div className="justify-evenly flex flex-col items-center h-full">
                                <img
                                    src={sponsor.logo}
                                    alt={sponsor.name}
                                    title={sponsor.title}
                                    className={`object-contain max-w-[300px] p-0 hover:opacity-80 mix-blend-multiply`}
                                />
                                {sponsor.title && (
                                    <h3 className="text-logo-indigo my-2 text-lg font-bold">
                                        {sponsor.title}
                                    </h3>
                                )}
                            </div>
                        </FadeSlideIn>
                    </a>
                ))}
            </div>
        </div>
    );
};

export default SponsorBox;
