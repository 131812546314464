const RadioButton = ({ entity, selected, handleClick }) => {

    return (
        <>
            <input
                type="radio"
                name="app"
                id={entity.id}
                value={entity.id}
                className="peer hidden"
                checked={+selected === +entity.id}
                onChange={() => handleClick(entity.id)}
            />
            <label
                htmlFor={entity.id}
                className={`inline-flex w-full h-full justify-between items-center hover:bg-slate-100  peer-checked:!bg-logo-blue peer-checked:!text-white rounded-2xl p-4 text-black border-2 cursor-pointer`}
            >
                <div className="block">
                    <img
                        src={entity.logo}
                        width={50}
                        height={50}
                        alt={entity.name}
                        className="w-[25px] h-[25px] md:w-[50px] md:h-[50px]"
                    />
                    <div className="w-full mt-1 text-[10px] font-semibold md:text-sm">
                        {entity.name}
                    </div>
                </div>
            </label>
        </>
    );
};

export default RadioButton;
