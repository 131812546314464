import ImageBox from "../../components/Image/ImageBox";
import Layout from "../../components/Layouts/Layout";
import Title from "../../components/Title/Title";
import apiClient from "../../api/apiClient";
import { useQuery } from "react-query";
import Loader from "../../components/Loader";
const HallOfHonor = () => {
  // fetch hof from api
  // const { data: Presidents, isLoading } = useQuery(["hof"], () =>
  //     apiClient.get("/hof/")
  // );

  const { data: Presidents, isLoading } = useQuery(
    ["hof"],
    async () => {
      const result = await apiClient.get("/hof/");
      return result.data;
    },
    {
      cacheTime: 3600000, // 1 hour
    }
  );

  return (
    <Layout size={7} title="Hall of Honor">
      <Title text="Hall of Honor" />
      <Loader isLoading={isLoading}>
        <div className="flex flex-wrap justify-center gap-6">
          {!!Presidents &&
            Presidents.map((member) => (
              <ImageBox key={member.id} member={member} />
            ))}
        </div>
      </Loader>
    </Layout>
  );
};

export default HallOfHonor;
