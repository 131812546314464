import { BrowserRouter } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import AppRoutes from "./routes/AppRoutes";
import Footer from "./components/Footer/Footer";
import LocomotiveScroll from "locomotive-scroll";
import "react-toastify/dist/ReactToastify.css";
import "./styles/index.css";

const App = () => {
    new LocomotiveScroll({});

    return (
        <AnimatePresence mode="wait" initial={true}>
            <BrowserRouter>
                <AppRoutes />
                <Footer />
            </BrowserRouter>
        </AnimatePresence>
    );
};

export default App;
