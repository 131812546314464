import Title from "../Title/Title";

const Conference = () => {
  return (
    <section className="w-full mb-10">
      <Title text="Our Conferences" />
      <img
        src="/assets/conference.png"
        className="w-full"
        alt="Future of AI in Pakistan"
      />
    </section>
  );
};
export default Conference;
