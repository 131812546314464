import Button from "../Button/Button";
const CompetitionDetails = ({ selected }) => {
    return (
        <section className="details flex items-center justify-center mx-auto my-5">
            <div className="gap-x-12 gap-y-6 md:flex-row flex flex-col py-4 mx-auto">
                {selected.details.map((detail) => (
                    <a
                        key={detail.title}
                        target="_blank"
                        rel="noreferrer"
                        href={detail.document}
                    >
                        <Button outlined download text={detail.title} />
                    </a>
                ))}
            </div>
        </section>
    );
};

export default CompetitionDetails;
