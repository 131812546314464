import { useEffect, useState } from "react";

const HeroImage = ({ selected }) => {
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        setIsVisible(false);
        let img = new Image();
        img.src = selected.highlight_image;
        img.onload = () => setIsVisible(true);
        return () => (img = null);
    }, [selected]);

    return (
        <section className="w-full px-6">
            <h3 className="md:text-4xl text-logo-blue py-12 text-2xl font-bold text-center">
                {selected.name}
            </h3>
            <div className="rounded-2xl xl:mx-0 group relative overflow-hidden cursor-pointer">
                <div className="md:opacity-0 rounded-xl group-hover:md:opacity-100 from-black/80 to-transparent bg-gradient-to-t -bottom-2 absolute inset-x-0 z-10 text-white transition duration-300 ease-in-out opacity-100 cursor-pointer">
                    <div className="transform-gpu bg-textGradient group-hover:md:opacity-100 group-hover:md:translate-y-0 p-4 pb-10 space-y-3 text-xl transition duration-300 ease-in-out translate-y-4">
                        <div className="md:block hidden text-lg font-bold text-white">
                            {selected.description}
                        </div>
                    </div>
                </div>
                <div
                    className={`w-full max-h-[720px]`}
                    style={{
                        filter: isVisible
                            ? "grayscale(0) blur(0px)"
                            : "grayscale(100%) blur(10px)",
                    }}
                >
                    <img
                        alt={selected.name}
                        width={1080}
                        height={720}
                        className="w-full bg-logo-blue max-h-[720px] transition duration-300 ease-in-out group-hover:scale-110"
                        src={selected.highlight_image}
                    />
                </div>
            </div>
            {/* for mobile devices only */}
            <div className="sm:text-base md:hidden block my-5 text-sm text-center">
                {selected.description}
            </div>
        </section>
    );
};

export default HeroImage;
