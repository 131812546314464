export default function LoadingSpinner() {
    return (
        <div className="flex items-center justify-center w-full">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                viewBox="0 0 50 100"
                width="200px"
                height="100px"
                className="scale-[2]"
            >
                <circle cx="6" cy="50" r="6" fill="#664ED8">
                    <animate
                        attributeName="opacity"
                        begin="0.1"
                        dur="1s"
                        repeatCount="indefinite"
                        values="0;1;0"
                    ></animate>
                </circle>
                <circle cx="26" cy="50" r="6" fill="#259DF9">
                    <animate
                        attributeName="opacity"
                        begin="0.2"
                        dur="1s"
                        repeatCount="indefinite"
                        values="0;1;0"
                    ></animate>
                </circle>
                <circle cx="46" cy="50" r="6" fill="#C653B4">
                    <animate
                        attributeName="opacity"
                        begin="0.3"
                        dur="1s"
                        repeatCount="indefinite"
                        values="0;1;0"
                    ></animate>
                </circle>
            </svg>
        </div>
    );
}
