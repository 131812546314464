import { motion } from "framer-motion";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import Navbar from "../Navbar/Navbar";
const variants = {
  hidden: { opacity: 0, x: 0, y: 20 },
  enter: { opacity: 1, x: 0, y: 0 },
  exit: { opacity: 0, x: 0, y: 20 },
};

const Layout = ({ children, title, size = 4 }) => {
  document.title = title !== "Home" ? title + " - SOFTEC'25" : "SOFTEC'25";

  const sizes = {
    3: "max-w-3xl",
    4: "max-w-4xl",
    5: "max-w-5xl",
    6: "max-w-6xl",
    7: "max-w-7xl",
    8: "max-w-[100rem]",
  };

  let width = sizes[size];

  useEffect(() => {
    if (title.includes("Home")) {
      document.body.classList.add("home");
    } else {
      document.body.classList.add("page");
    }

    return () => {
      if (title.includes("Home")) {
        document.body.classList.remove("home");
      } else {
        document.body.classList.remove("page");
      }
    };
  }, [title]);

  return (
    <>
      <Navbar />
      <ToastContainer />
      <div
        className={`${
          !title.includes("Home") ? "mt-[110px]" : ""
        } flex min-h-full items-center justify-center`}
      >
        <div className={`#page w-full ${width}`}>
          <motion.article
            initial="hidden"
            animate="enter"
            exit="exit"
            variants={variants}
            transition={{ duration: 0.45, type: "easeInOut" }}
            style={{ position: "relative" }}
          >
            {children}
          </motion.article>
        </div>
      </div>
    </>
  );
};

export default Layout;
