import Loader from "../Loader";
import Title from "../Title/Title";
import Marquee from "react-fast-marquee";
import apiClient from "../../api/apiClient";
import { useQuery } from "react-query";
import Error from "../Error";

const TechnicalCollaborators = () => {
    const {
        data: TechCollaborators,
        error,
        isLoading,
    } = useQuery(
        "techCollaborators",
        async () => {
            const response = await apiClient.get("/tech-collaborators/");
            return response.data;
        },
        {
            cacheTime: 300000, // 5 minutes
        }
    );

    if (error) {
        return <Error />;
    }

    if (TechCollaborators) {
        return (
            <section className="md:mt-20 md:w-full w-10/12 mx-auto mt-10">
                <Title text="Technical Collaborators" />
                <Loader isLoading={isLoading}>
                    <Marquee
                        className="technicalMarquee"
                        gradient
                        speed={80}
                        pauseOnClick
                        pauseOnHover
                        direction="right"
                    >
                        {TechCollaborators.map((tech) => (
                            <a
                                key={tech.id}
                                href={tech.link}
                                target="_blank"
                                rel="noreferrer"
                                className="md:mx-10 p-4 mx-5 cursor-pointer"
                            >
                                <img
                                    src={tech.logo}
                                    alt={tech.title}
                                    title={tech.title}
                                    loading="lazy"
                                    className="maw-w-[200px] object-cover p-0"
                                />
                            </a>
                        ))}
                    </Marquee>
                </Loader>
            </section>
        );
    }
};

export default TechnicalCollaborators;
