import Title from "../Title/Title";

const CompetitionCollaborators = ({ selected }) => {
    return (
        <section className="sponsor w-full mt-10">
            <Title text="Technical Collaborators" />
            <div className="sm:m-auto flex flex-wrap justify-center gap-6 mx-10">
                {selected.technical_collaborator.map((collaborator) => (
                    <img
                        key={collaborator.name}
                        src={collaborator.logo}
                        className="object-contain"
                        width={300}
                        alt={collaborator.name}
                    />
                ))}
            </div>
        </section>
    );
};

export default CompetitionCollaborators;
