import CountUp from "react-countup";

const COUNTERS = [
  {
    title: "Edition of SOFTEC",
    count: 29,
    suffix: "th",
  },
  {
    title: "Participants",
    count: 2200,
    suffix: "+",
  },
  {
    title: "Sponsors",
    count: 22,
    suffix: "+",
  },
  {
    title: "Footfall",
    count: 17000,
    suffix: "+",
  },
  {
    title: "Competitions",
    count: 20,
  },
  {
    title: "Prize Money",
    count: 1.8,
    suffix: "M",
  },
  {
    title: "Community partners",
    count: 28,
    suffix: "+",
  },
  {
    title: "Ambassadors",
    count: 300,
    suffix: "+",
  },
  {
    title: "Collaborators",
    count: 7,
  },
  {
    title: "Media Partners",
    count: 13,
  },
  {
    title: "Cities",
    count: 8,
    suffix: "+",
  },
  {
    title: "Universities",
    count: 25,
    suffix: "+",
  },
];

const isInteger = (num) => num === Math.floor(num);

const Counters = () => {
  return (
    <section className="bg-primary w-full py-24 my-20">
      <div className="md:w-5/6 md:grid-cols-3 place-items-center gap-x-20 gap-y-10 md:flex-row grid grid-cols-1 mx-auto text-white">
        {COUNTERS.map((counter) => (
          <div
            key={counter.count}
            className="flex border-b-2 flex-col w-[300px] md:w-[400px] h-[200px] justify-center items-center text-center gap-y-4 "
          >
            <h2 className="text-5xl font-bold ml-[10px]">
              <CountUp
                enableScrollSpy
                scrollSpyOnce
                decimals={isInteger(counter.count) ? 0 : 1}
                delay={1}
                end={counter.count}
                suffix={counter.suffix || ""}
                duration={1.5}
              />
            </h2>
            <h3 className="text-3xl font-bold uppercase">{counter.title}</h3>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Counters;
