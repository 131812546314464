import { useEffect, useState } from "react";
import { menuList } from "./menuList";
import { NavLink } from "react-router-dom";
import { imagesArr } from "../../assets";
const Navbar = () => {
    const [open, setOpen] = useState(false);

    function handleClick(event) {
        event.target.parentElement.classList.toggle("active");
    }

    const changeScroll = () => {
        if (window.scrollY >= 100) {
            document.querySelector(".navWrapper")?.classList.add("scrolled");
        } else {
            document.querySelector(".navWrapper")?.classList.remove("scrolled");
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", changeScroll, { passive: true });
        return () => {
            window.removeEventListener("scroll", changeScroll, {
                passive: true,
            });
        };
    }, []);

    return (
        <div className="fixed py-2 navWrapper md:py-5 bg-logo-black top-0 w-full z-[100]">
            <nav className="relative flex flex-row max-w-[calc(100%-50px)] mx-auto my-0 h-[70px] items-center justify-center ">
                <div
                    className={`cursor-pointer menuIcon hidden ${
                        open ? "opened" : ""
                    }`}
                    onClick={() => setOpen((open) => !open)}
                >
                    {open ? (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 320 512"
                            fill="#F9F9F9"
                            className="scale-90 w-[30px] md:w-[40px] "
                        >
                            <path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" />
                        </svg>
                    ) : (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                            fill="#F9F9F9"
                            className="w-[30px] md:w-[40px] "
                        >
                            <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
                        </svg>
                    )}
                </div>
                <div className="content justify-evenly flex items-center w-full">
                    <div className="logo -ml-[12px]">
                        <NavLink
                            to="/"
                            className="flex items-center text-white text-[30px] font-semibold"
                        >
                            <img
                                src={imagesArr.Logo}
                                alt="logo"
                                className="w-[150px] md:w-[225px]"
                                // className="w-[60px] md:w-[80px]"
                            />
                            {/* <h2 className="md:block hidden">SOFTEC'24</h2> */}
                        </NavLink>
                    </div>
                    <ul className="links flex items-center w-auto">
                        {menuList.map((route, index) => (
                            <li
                                key={index}
                                id={route.url}
                                onClick={handleClick}
                                className={` list-none relative leading-[50px] group ${
                                    route.subMenu ? "has-submenu" : ""
                                }`}
                            >
                                <NavOrSpan route={route}>
                                    {route.subMenu && (
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="#F9F9F9"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M12 15.375L6 9.375L7.075 8.3L12 13.25L16.925 8.325L18 9.4L12 15.375Z"
                                                fill="#F9F9F9"
                                            />
                                        </svg>
                                    )}
                                </NavOrSpan>

                                {route.subMenu && (
                                    <>
                                        <ul className="px-4 group-hover:top-[50px] left-0  group-hover:opacity-100 group-hover:visible group-hover:transition-all group-hover:duration-300 group-hover:ease-in-out sub-menu absolute bg-logo-black py-[8px] top-[70px] z-[-1] opacity-0 invisible transition-all duration-300 ease-in-out">
                                            {route.subMenu.map((sub, index) => (
                                                <li key={index}>
                                                    <NavLink
                                                        to={sub.url}
                                                        className="text-white w-full leading-[30px] hover:bg-[#323c4e] text-[18px] flex flex-row items-center justify-start cursor-pointer font-bold py-[9px] transition-all duration-300 ease-in-out px-[17px] "
                                                    >
                                                        {sub.title}
                                                    </NavLink>
                                                </li>
                                            ))}
                                        </ul>
                                    </>
                                )}
                            </li>
                        ))}
                    </ul>
                    <div className="w-[50px] h-[50px] md:h-[75px] md:w-[75px] fastlogo">
                        <a
                            href="http://lhr.nu.edu.pk/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                src="/assets/fastnu.webp"
                                alt="FAST NU logo"
                                width={100}
                                height={100}
                                className=" object-contain cursor-pointer"
                            />
                        </a>
                    </div>
                </div>
            </nav>
        </div>
    );
};

export default Navbar;

function NavOrSpan({ route, children }) {
    return route.url ? (
        <NavLink
            to={route.url}
            className="text-white hover:bg-[#323c4e] flex flex-row items-center justify-center cursor-pointer text-[18px] font-bold py-0 px-[17px] rounded-[5px] transition-all duration-300 ease-in-out"
        >
            {route.title} {children}
        </NavLink>
    ) : (
        <span className="text-white hover:bg-[#323c4e] flex flex-row items-center justify-center cursor-pointer text-[18px] font-bold py-0 px-[17px] rounded-[5px] transition-all duration-300 ease-in-out">
            {route.title} {children}
        </span>
    );
}
