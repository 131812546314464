import Instagram from "./svg/instagram.svg";
import Twitter from "./svg/twitter.svg";
import FacebookOutlined from "./svg/facebook-outlined.svg";
import Logo from "./softec.png";
import LogoMini from "./softec-mini.png";
import LinkedIn from "./svg/linkedin.svg";
import Youtube from "./svg/youtube.svg";
import Bars from "./svg/bars.svg";
import Close from "./svg/close.svg";
import Person from "./svg/person.svg";
import Dollar from "./svg/dollar.svg";
import SilverAward from "./silver.webp";
import GoldAward from "./gold.webp";
import BronzeAward from "./bronze.webp";
import Welcome from "./svg/welcome.svg";
import LogoRed from "./softec-red-logo.png";
import HandCV from "./cv.webp";

export const imagesArr = {
    Instagram,
    Twitter,
    FacebookOutlined,
    Logo,
    LogoMini,
    LinkedIn,
    Youtube,
    Bars,
    Close,
    Person,
    Dollar,
    SilverAward,
    GoldAward,
    BronzeAward,
    Welcome,
    LogoRed,
    HandCV,
};