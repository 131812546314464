
import { Navigate, Route, Routes } from "react-router-dom";
import Ambassadors from "../pages/Ambassadors/Ambassadors";
import Competitions from "../pages/Competiitons/Competiitons";
import Contact from "../pages/Contact/Contact";
import Exhibitions from "../pages/Exhibitions/Exhibitions";
import HallOfHonor from "../pages/HallOfHonor/HallOfHonor";
import Home from "../pages/Home/Home";
import AmbassadorRegistration from "../pages/Registrations/AmbassadorRegistration";
import CompetitionRegistration from "../pages/Registrations/CompetitionRegistration";
import OfficerRegistration from "../pages/Registrations/OfficerRegistration";
import Sponsors from "../pages/Sponsors/Sponsors";
import OurTeam from "../pages/Team/Team";
// import HallOfHeroes from "../pages/HallOfHeroes/HallOfHeroes";
import SmoothScroll from "../utils/SmoothScroll";
import PaymentVerification from "../pages/Registrations/PaymentVerifcation";
import CodeOfConduct from "../pages/CodeOfConduct/CodeOfConduct";
const AppRoutes = () => {
    return (
        <SmoothScroll>
            <Routes>
                <Route path="/">
                    <Route index element={<Home />} />
                    <Route path="contact" element={<Contact />} />
                    <Route path="team" element={<OurTeam />} />
                    <Route
                        path="registration/ambassador"
                        element={<AmbassadorRegistration />}
                    />
                    <Route
                        path="registration/officer"
                        element={<OfficerRegistration />}
                    />
                    <Route
                        path="registration/competition"
                        element={<CompetitionRegistration />}
                    />
                    <Route
                        path="registration/verify"
                        element={<PaymentVerification />}
                    />
                    <Route path={`competitions/`} element={<Competitions />} />
                    <Route path={`exhibitions/`} element={<Exhibitions />} />
                    <Route path={`ambassadors/`} element={<Ambassadors />} />
                    <Route path={`hall-of-honor/`} element={<HallOfHonor />} />
                    <Route
                        path={`code-of-conduct/`}
                        element={<CodeOfConduct />}
                    />
                    {/* <Route
                        path={`hall-of-heroes/`}
                        element={<HallOfHeroes />}
                    /> */}
                    <Route path={`sponsors/`} element={<Sponsors />} />
                    <Route path="*" element={<Navigate to="/" replace />} />
                </Route>
            </Routes>
        </SmoothScroll>
    );
};

export default AppRoutes;
