const Spacer = ({ height = 100 }) => {
    let space = "h-[100px]";
    switch (height) {
        case 200:
            space = "h-[200px]";
            break;
        case 300:
            space = "h-[300px]";
            break;
        case 400:
            space = "h-[400px]";
            break;
        default:
            space = "h-[100px]";
            break;
    }
    return <div className={`${space} w-full`}></div>;
};

export default Spacer;
