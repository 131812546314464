import { useState, createRef } from "react";
import { toast } from "react-toastify";
import apiClient from "../../api/apiClient.js";
import Button from "../../components/Button/Button.jsx";
import Input from "../../components/Input/Input.jsx";
import Layout from "../../components/Layouts/Layout.jsx";
import Spacer from "../../components/Spacer/Spacer.jsx";
import Title from "../../components/Title/Title.jsx";
import useConfig from "../../utils/useConfig.jsx";
import Loader from "../../components/Loader.jsx";
import ReCAPTCHA from "react-google-recaptcha";

const PaymentVerification = () => {
    const recaptchaRef = createRef();

    const [form, setForm] = useState({
        transation_id: "",
        amount: "",
        iban: "",
        holder_name: "",
        bank_name: "",
        team_name: "",
        payment_date: "",
        team_rrn: "",
        screenshot: "",
    });
    const [loading, setLoading] = useState(false);

    const { isOpen, isLoading } = useConfig("team");

    const handleSubmit = async (e) => {
        e.preventDefault();

        const token = await recaptchaRef.current.getValue();
        // if not token then return
        if (!token) {
            toast.error("Please verify you are not a robot!");
            recaptchaRef.current.reset();
            return;
        }

        if (
            !(
                form.transation_id &&
                form.amount &&
                form.iban &&
                form.holder_name &&
                form.bank_name &&
                form.team_name &&
                form.payment_date &&
                form.screenshot
            )
        ) {
            toast.error("Please fill all the fields");
            return;
        }

        // convert date to dd-mm-yyyy format
        const date = new Date(form.payment_date);
        const payment_date = `${date.getDate()}-${
            date.getMonth() + 1
        }-${date.getFullYear()}`;

        const formData = new FormData();
        formData.append("transaction_id", form.transation_id);
        formData.append("amount", form.amount);
        formData.append("iban", form.iban);
        formData.append("holder_name", form.holder_name);
        formData.append("bank_name", form.bank_name);
        formData.append("team_name", form.team_name);
        formData.append("team_rrn", form.team_rrn);
        formData.append("payment_date", payment_date);
        formData.append("screenshot", form.screenshot);
        formData.append("token", token);

        setLoading(true);
        apiClient
            .post("/verify/", formData)
            .then((res) => {
                if (res.status === 200) {
                    toast.success(
                        "Request received! You'll be notified via email once your payment is verified."
                    );
                    setForm({
                        transation_id: "",
                        amount: "",
                        iban: "",
                        holder_name: "",
                        bank_name: "",
                        team_name: "",
                        payment_date: "",
                        team_rrn: "",
                        screenshot: "",
                    });
                }
            })
            .catch((err) => {
                console.log({ err });
                toast.error(err.response.data.message || err.message);
            })
            .finally(() => {
                setLoading(false);
                recaptchaRef.current.reset();
            });
    };

    const handleChange = (e) => {
        setForm((form) => ({ ...form, [e.target.name]: e.target.value }));
    };

    return (
        <Layout size={3} title="Payment Verifcation">
            <Title text="Payment Verifcation" />
            <Loader isLoading={isLoading}>
                {!isLoading && isOpen ? (
                    <form className="px-6" onSubmit={handleSubmit}>
                        <p className="mb-12 -mt-10 text-center">
                            It usually takes 5 to 7 working days to verify the
                            payment. If you have already submitted a request,
                            please wait for the confirmation email. For any kind
                            of payment related issues, reach out to{" "}
                            <a
                                href="mailto:akhtar@softec.org.pk"
                                className="text-blue-500"
                            >
                                akhtar@softec.org.pk
                            </a>
                            .
                        </p>

                        <div className="md:grid-cols-2 md:gap-6 grid">
                            <Input
                                text="Transaction ID"
                                value={form.transation_id}
                                name="transation_id"
                                placeholder="Enter Transaction ID"
                                onChange={handleChange}
                                required
                            />

                            <Input
                                text="Amount"
                                value={form.amount}
                                name="amount"
                                type="number"
                                placeholder="3000"
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="md:grid-cols-2 md:gap-6 grid">
                            <Input
                                text="IBAN/Account Number"
                                type="text"
                                name="iban"
                                value={form.iban}
                                placeholder="Enter IBAN/Account Number"
                                onChange={handleChange}
                                required
                            />

                            <Input
                                text="Payment Date"
                                type="date"
                                value={form.payment_date}
                                name="payment_date"
                                placeholder="dd-mm-yyyy"
                                min="1997-01-01"
                                max="2030-12-31"
                                required
                                onChange={handleChange}
                            />
                        </div>

                        <div className="md:grid-cols-2 md:gap-6 grid">
                            <Input
                                text="Account Holder Name"
                                value={form.holder_name}
                                name="holder_name"
                                placeholder="John Doe"
                                onChange={handleChange}
                                required
                            />

                            <Input
                                text="Bank Name"
                                value={form.bank_name}
                                name="bank_name"
                                placeholder="Faisal Bank"
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="md:grid-cols-2 md:gap-6 grid">
                            <Input
                                text="Team RRN Number"
                                value={form.team_rrn}
                                name="team_rrn"
                                placeholder="PC-0000"
                                onChange={handleChange}
                                required
                            />

                            <Input
                                text="Team Name"
                                value={form.team_name}
                                name="team_name"
                                placeholder="Enter Team Name"
                                required
                                onChange={handleChange}
                            />
                        </div>

                        <Input
                            text="Screenshot"
                            type="file"
                            name="screenshot"
                            // file type
                            accept="image/*"
                            helperText="Make sure that the screenshot has transation datetime and amount clearly visible."
                            onChange={(e) =>
                                setForm((form) => ({
                                    ...form,
                                    screenshot: e.target.files[0],
                                }))
                            }
                            required
                        />
                        <ReCAPTCHA
                            className="my-5"
                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                            ref={recaptchaRef}
                        />

                        <Button loading={loading} text="Submit" />
                    </form>
                ) : (
                    <>
                        <h1 className="md:text-2xl mt-5 text-xl text-center text-red-500">
                            Closed!
                        </h1>
                        <Spacer height={400} />
                    </>
                )}
            </Loader>
        </Layout>
    );
};

export default PaymentVerification;
