const ContactTile = ({ contact }) => {
    return (
        <div
            key={contact.id}
            className="p-10 md:min-h-[242px] md:min-w-[350px] bg-gray-100 text-[15px] relative overflow-hidden border"
        >
            <h3 className="md:text-3xl mb-5 text-2xl font-bold">
                {contact.title}
            </h3>
            <p>
                <b>Name: </b> {contact.name}
            </p>
            <p>
                <b>Position: </b> {contact.position}
            </p>
            <p>
                <b>Phone: </b>
                <a
                    className="hover:underline hover:text-logo-indigo"
                    href={`tel:${contact.phoneLink}`}
                >
                    {contact.phone}
                </a>
            </p>
            <p>
                <b>Email: </b>
                <a
                    className="hover:underline hover:text-logo-indigo"
                    href={`mailto:${contact.email}`}
                >
                    {contact.email}
                </a>
            </p>
            <div className="w-[120px] h-[120px] bg-logo-indigo rounded-full absolute bottom-[-55px] right-[-55px] py-[25px] px-[30px] ">
                <svg
                    className="w-[16px]"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    fill="#fff"
                >
                    <path d="M498.1 5.6c10.1 7 15.4 19.1 13.5 31.2l-64 416c-1.5 9.7-7.4 18.2-16 23s-18.9 5.4-28 1.6L284 427.7l-68.5 74.1c-8.9 9.7-22.9 12.9-35.2 8.1S160 493.2 160 480V396.4c0-4 1.5-7.8 4.2-10.7L331.8 202.8c5.8-6.3 5.6-16-.4-22s-15.7-6.4-22-.7L106 360.8 17.7 316.6C7.1 311.3 .3 300.7 0 288.9s5.9-22.8 16.1-28.7l448-256c10.7-6.1 23.9-5.5 34 1.4z" />
                </svg>
            </div>
        </div>
    );
};

export default ContactTile;
