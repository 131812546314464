import React from "react";

const StepIndicator = ({ steps = 0, step }) => {
    return (
        <ol className="flex items-center justify-center px-10 mb-10">
            {[...Array(steps)].map((_, index) => (
                <li
                    key={index}
                    className={`relative ${
                        index !== steps - 1
                            ? "w-[200px]"
                        : "md:w-[10px] w-[20px]"
                    } mb-6`}
                >
                    <div className="flex items-center">
                        <div
                            className={`z-10 flex items-center justify-center w-6 h-6 ${
                                step === index && "bg-blue-200"
                            }  rounded-full ring-0 ring-white sm:ring-8 shrink-0`}
                        >
                            <span
                                className={`flex w-3 h-3 ${
                                    step >= index + 1
                                        ? "bg-logo-blue"
                                        : "bg-gray-600"
                                } rounded-full`}
                            ></span>
                        </div>
                        {index !== steps - 1 && (
                            <div className="flex w-full bg-gray-200 h-0.5"></div>
                        )}
                    </div>
                </li>
            ))}
        </ol>
    );
};

export default StepIndicator;
