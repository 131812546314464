import Tooltip from "../../utils/Tooltip";

const Input = ({ text, type, list, tooltip, helperText, ...rest }) => {
    const renderElement = () => {
        switch (type) {
            case "textarea":
                return (
                    <textarea
                        type="text"
                        spellCheck={false}
                        {...rest}
                        className={`bg-gray-50 focus:outline-none focus:shadow-input block w-full p-3 text-sm text-gray-900 border border-gray-400 rounded-lg`}
                    />
                );

            case "select":
                return (
                    <select
                        className={`bg-gray-50 focus:outline-none focus:shadow-input block w-full p-3 text-sm text-gray-900 border border-gray-400 rounded-lg`}
                        {...rest}
                    >
                        <option value="">{`Select ${text}`}</option>
                        {list.map((element) => (
                            <option key={element.id} value={element.id}>
                                {element.name}
                            </option>
                        ))}
                    </select>
                );

            default:
                return (
                    <input
                        type={type || "text"}
                        {...rest}
                        className={`bg-gray-50 focus:outline-none focus:shadow-input block w-full p-3 text-sm text-gray-900 border border-gray-400 rounded-lg`}
                    />
                );
        }
    };

    return (
        <div className="flex-1 mb-6">
            <label className="dark:text-gray-400 block mb-2 text-sm font-medium text-gray-900">
                <span className="group relative flex items-center gap-2">
                    <span>{text}</span>
                    {rest.required && (
                        <span className="text-base leading-none text-red-500">
                            *
                        </span>
                    )}

                    {tooltip && <Tooltip text={tooltip} />}
                </span>
            </label>
            {renderElement()}
            {helperText && (
                <p className="m-2 text-xs leading-tight text-red-400">
                    {helperText}
                </p>
            )}
        </div>
    );
};

export default Input;
