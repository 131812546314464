
const Tooltip = ({ text }) => {
	return (
        <div className="group relative flex flex-col items-center">
            <Icon />
            <div className="group-hover:flex absolute bottom-0 flex-col items-center hidden mb-6">
                <span className="relative w-[230px] z-10 p-2 text-xs leading-2 text-white whitespace-no-wrap bg-black shadow-lg">
                    {" "}
                    {text}
                </span>
                <div className="w-3 h-3 -mt-2 rotate-45 bg-black"></div>
            </div>
        </div>
    );
}

export default Tooltip
function Icon() {
	return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            className="text-logo-blue w-5 h-5 cursor-pointer"
            viewBox="0 0 20 20"
        >
            <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}