export const menuList = [
    {
        title: "Home",
        url: "/",
    },
    {
        title: "Competitions",
        url: "/competitions",
    },
    {
        title: "Exhibitions",
        url: "/exhibitions",
    },

    {
        title: "Hall of Honor",
        url: "/hall-of-honor",
    },
    {
        title: "Our Team",
        url: "/team",
    },
    {
        title: "Ambassadors",
        url: "/ambassadors",
    },
    {
        title: "Registration",
        subMenu: [
            {
                title: "Officer",
                url: "/registration/officer",
            },
            {
                title: "Ambassador",
                url: "/registration/ambassador",
            },
            {
                title: "Competition",
                url: "/registration/competition",
            },
            {
                title: "Verification",
                url: "/registration/verify",
            },
        ],
    },
    // {
    //     title: "Code of Conduct",
    //     url: "/code-of-conduct",
    // },
    {
        title: "Sponsors",
        url: "/sponsors",
    },
    {
        title: "Contact",
        url: "/contact",
    },
];
