import { useEffect } from "react";
import Button from "../Button/Button";

const SuccessRegistration = ({ message, setForm }) => {

    console.log({ message });

    const handlePrev = () => {
        setForm((form) => ({
            ...form,
            competitionId: "",
            teamName: "",
            institute: "",
            ambassador: "",
            discipline: "",
            accommodation: "",
            members: [],
            step: 0,
            selectedCompetition: {},
        }));
    };

    useEffect(() => {
        window.scrollBy({
            top: -window.innerHeight,
            left: 0,
            behavior: "smooth",
        });
    }, []);

    return (
        <div className="px-5 mb-5">
            <h1 className="md:text-3xl mb-5 text-xl font-bold text-center">
                Registration Successful!
            </h1>

            <p className=" text-center">
                Team Registered Successfully! Your Team RRN Number is{" "}
                <b className="text-primary whitespace-nowrap">
                    {message.rrn}.{" "}
                </b>
                Your Grand Total is
                <b> {message.total} PKR. </b>A confirmation email with payment
                details has been sent to your email addresss.
            </p>

            <div className="mt-4 space-y-4">
                <p
                    style={{
                        textAlign: "center",
                    }}
                >
                    The fee must be paid at the earliest to confirm your
                    participation. For both <b>national</b> and{" "}
                    <b>international participants</b>, registration fee can be
                    submitted in the below mentioned bank account:
                </p>
                <p>
                    Bank Name: <strong>Faysal Bank</strong>
                </p>
                <p>
                    Account Title: <strong>BCCI- FAST NUCES (LHE OPER)</strong>
                </p>
                <p>
                    Account Number: <strong>PK37FAYS0169007900161007</strong>
                </p>
                <p>
                    After depositing the fee, visit the following link to
                    confirm your payment:{" "}
                    <a
                        className="text-logo-blue font-medium"
                        href="https://softecnu.org/registration/verify"
                    >
                        https://softecnu.org/registration/verify
                    </a>
                </p>

                <p>
                    <strong>Note</strong>: The registration fee is
                    non-refundable.
                </p>
                <p>
                    Moreover, complete payment procedure has been mailed to all
                    the team members as well.{" "}
                </p>
            </div>
            <div className="flex gap-6 mt-10">
                <Button type="button" text="Back" onClick={handlePrev} />
            </div>
        </div>
    );
};

export default SuccessRegistration;
