import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import apiClient from "../../api/apiClient";
import Competition from "../../components/CompetitionRegistration/Competition";
import TeamMembersRegistration from "../../components/CompetitionRegistration/TeamMembersRegistration";
import TeamRegistration from "../../components/CompetitionRegistration/TeamRegistration";
import StepIndicator from "../../components/Indicator/StepIndicator";
import Layout from "../../components/Layouts/Layout";
import Spacer from "../../components/Spacer/Spacer";
import Title from "../../components/Title/Title";
import LoadingWrapper from "../../utils/LoadingWrapper";
import useConfig from "../../utils/useConfig.jsx";
import Loader from "../../components/Loader.jsx";
import { Link } from "react-router-dom";
import Error from "../../components/Error.jsx";
const CompetitionRegistration = () => {
  const [form, setForm] = useState({
    step: 0,
    competitionId: "",
    selectedCompetition: {},
    teamName: "",
    institute: "",
    ambassador: "",
    accommodation: "",
    food: "",
    teamMembers: [],
    config: {},
  });

  // fetch competitions from api
  const {
    data: Competitions = [],
    isLoading,
    isError,
  } = useQuery(
    ["competitions-registerable"],
    async () => {
      const result = await apiClient.get("/competitions_registrable/");
      return result.data;
    },
    {
      cacheTime: 300000, // 5 minutes
    }
  );

  const WrappedCompetitionComponent = LoadingWrapper(Competition);
  const { isOpen, config, isLoading: isConfigLoading } = useConfig("team");

  useEffect(() => {
    setForm((form) => ({
      ...form,
      config: config,
    }));
  }, [config]);

  const conditionalComponent = () => {
    if (isLoading) {
      return <Loader isLoading={true} />;
    }

    if (isError) {
      return <Error />;
    }

    if (!Competitions || !Array.isArray(Competitions)) {
      return <p className="text-center">Competitions data is not available.</p>;
    }
    switch (form.step) {
      case 0:
        return (
          <WrappedCompetitionComponent
            setForm={setForm}
            form={form}
            competitions={Competitions}
            isLoading={isLoading}
          />
        );
      case 1:
        return (
          <TeamRegistration
            setForm={setForm}
            form={form}
            competitions={Competitions}
          />
        );
      case 2:
        return (
          <TeamMembersRegistration
            setForm={setForm}
            form={form}
            competitions={Competitions}
          />
        );

      default:
        return (
          <WrappedCompetitionComponent
            setForm={setForm}
            form={form}
            competitions={Competitions}
            isLoading={isLoading}
          />
        );
    }
  };

  return (
    <Layout size={3} title="Competition Registration">
      <Title text="Competition Registration" />

      {isOpen && (
        <>
          <p className="my-1 text-center">
            For detailed information of competitions, please visit the{" "}
            <Link className="text-blue-500" to="/competitions/">
              Competitions{" "}
            </Link>
            page.
          </p>

          <p className="mt-1 mb-3 text-center">
            For any kind of issues or help, please contact us at{" "}
            <a href="mailto:web@softec.org.pk" className="text-blue-500">
              web@softec.org.pk
            </a>
          </p>
          <StepIndicator steps={3} step={form.step} />
        </>
      )}
      <section className="md:w-3/4 flex flex-col items-center justify-center w-full mx-auto">
        <div className="w-full max-w-[600px]">
          <Loader isLoading={isConfigLoading}>
            {!isOpen ? (
              <>
                <h1 className="md:text-2xl mt-5 text-xl text-center text-red-500">
                  Registration is Closed!
                </h1>
                <Spacer height={300} />
              </>
            ) : (
              conditionalComponent()
            )}
          </Loader>
        </div>
      </section>
    </Layout>
  );
};

export default CompetitionRegistration;
