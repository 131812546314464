import apiClient from "../../api/apiClient";
import SponsorBox from "./SponsorBox";
import { useQuery } from "react-query";
import Loader from "../Loader";
import Error from "../Error";
const SponsorList = ({ cols = 3 }) => {
    const {
        data: Sponsors,
        error,
        isLoading,
    } = useQuery(
        "sponsors",
        async () => {
            const response = await apiClient.get("/sponsors/");
            return response.data;
        },
        {
            cacheTime: 300000, // 5 minutes
        }
    );

    if (error) {
        return <Error />;
    }
    if (Sponsors) {
        return (
            <div className="flex justify-center mx-auto flex-col w-[95%] md:w-3/5">
                <Loader isLoading={isLoading}>
                    {Object.keys(Sponsors).map((level) => (
                        <SponsorBox
                            key={level}
                            cols={cols}
                            sponsorList={Sponsors[level]}
                            level={level}
                        />
                    ))}
                </Loader>
            </div>
        );
    }
};

export default SponsorList;
