import Title from "../../components/Title/Title";
import Layout from "../../components/Layouts/Layout";

const CodeOfConduct = () => {
	return (
		<Layout size={5} title="Code of Conduct">
			<Title text="Code of Conduct" />
			<main className="mx-6 md:mx-0">
				<section className="mb-16">
					<p className="text-lg leading-8">
						SOFTEC is a student-led organization that aims to
						promote and celebrate technology and innovation. As
						such, we are committed to providing a welcoming,
						inclusive, and safe environment for all participants in
						our events, both online and offline. This Code of
						Conduct outlines our expectations for behavior and sets
						the standards for our community. By participating in
						SOFTEC'23, you agree to abide by this Code of Conduct
						and to help us create a positive and respectful
						experience for everyone.
					</p>
				</section>

				<section className="mb-16">
					<h2 className="py-4 text-2xl font-bold">Our Values</h2>
					<p className="text-lg leading-8">We value:</p>
					<ul className="pl-8 list-disc">
						<li className="leading-8">
							<b>Inclusivity:</b> We welcome and respect people of
							all backgrounds, identities, and experiences. We
							strive to create an environment where everyone feels
							included and valued, and where diversity is
							celebrated.
						</li>
						<li className="leading-8">
							<b>Integrity:</b> We conduct ourselves with honesty,
							transparency, and professionalism. We take
							responsibility for our actions and hold ourselves
							and others accountable for upholding the standards
							of our community.
						</li>
						<li className="leading-8">
							<b>Collaboration:</b> We work together to achieve
							our goals and support each other in our endeavors.
							We encourage communication, feedback, and
							constructive criticism as means to improve ourselves
							and our work.
						</li>
						<li className="leading-8">
							<b>Innovation:</b> We embrace creativity,
							experimentation, and learning. We challenge
							ourselves to push the boundaries of what is possible
							and to think outside the box.
						</li>
					</ul>
				</section>

				<section className="mb-16">
					<h2 className="py-4 text-2xl font-bold">
						Expected Behavior
					</h2>
					<p className="text-lg leading-8">
						All participants in SOFTEC'23 are expected to:
					</p>
					<ul className="pl-8 list-disc">
						<li className="leading-8">
							<b>Treat others with respect: </b> We do not
							tolerate discrimination, harassment, or any form of
							intimidation or abuse. This includes, but is not
							limited to, bullying, slurs, hate speech, threats,
							or violence. We respect people's boundaries and
							privacy, and we do not engage in behavior that makes
							them feel uncomfortable or unsafe.
						</li>
						<li className="leading-8">
							<b>Listen actively and communicate clearly: </b> We
							encourage open and honest communication, but we also
							expect participants to listen actively and to be
							considerate of others' opinions and feelings. We do
							not engage in disruptive or offensive behavior, such
							as shouting, interrupting, or monopolizing
							conversations.
						</li>
						<li className="leading-8">
							<b>Follow the rules and guidelines: </b> We expect
							participants to comply with the rules and guidelines
							of SOFTEC'23 and to follow the instructions of the
							organizers and volunteers. We do not engage in
							behavior that violates the law or puts others at
							risk.Respect intellectual property: We respect the
							intellectual property of others, including their
							ideas, designs, code, and content. We do not engage
							in plagiarism, piracy, or any other form of theft or
							misuse of intellectual property.
						</li>
						<li className="leading-8">
							<b>
								Represent ourselves and SOFTEC with integrity:{" "}
							</b>
							We represent ourselves and SOFTEC with honesty,
							transparency, and professionalism. We do not engage
							in behavior that could harm our reputation or
							credibility, such as spreading false information or
							engaging in conflicts of interest.
						</li>
					</ul>
				</section>

				<section className="mb-16">
					<h2 className="py-4 text-2xl font-bold">
						Consequences of Misconduct
					</h2>
					<p className="text-lg leading-8">
						We take any violation of this Code of Conduct seriously
						and will take appropriate action to address it.
						Depending on the severity and frequency of the
						misconduct, we may take any of the following actions:
					</p>
					<ul className="pl-8 list-disc">
						<li className="leading-8">
							<b>Warning or Disciplinary Action: </b> We may issue
							a verbal or written warning to the offender, either
							directly or through a mediator or a trusted person.
						</li>
						<li className="leading-8">
							<b>Removal from the event: </b> We may ask the
							offender to leave the event, either temporarily or
							permanently, and to refrain from further
							participation.
						</li>
						<li className="leading-8">
							<b>Ban from future events: </b> We may prohibit the
							offender from participating in future SOFTEC events,
							either permanently or for a certain period of time.
						</li>
						<li className="leading-8">
							<b>Legal action: </b>
							We may take legal action if the misconduct violates
							the law or if it poses a significant threat to the
							safety, security, or well-being of others.
						</li>
					</ul>
					<p className="my-6 text-lg leading-8">
						We will investigate any reported or observed misconduct
						in a fair, impartial, and confidential manner, and we
						will take measures to protect the privacy and safety of
						all parties involved. We encourage anyone who witnesses
						or experiences misconduct to report it promptly to the
						organizers or volunteers, either in person or through
						the designated channels.
					</p>
				</section>

				<section className="mb-16">
					<h2 className="py-4 text-2xl font-bold">
						Reporting and Resolving Misconduct
					</h2>
					<p className="text-lg leading-8">
						If you witness or experience any violation of this Code
						of Conduct, or if you have any concerns or questions
						about it, please report it promptly to the organizers or
						volunteers. You can do so in person, by email, or
						through the designated channels provided at the event.
						Your report will be taken seriously and handled with
						discretion and confidentiality.
					</p>
					<p className="my-6 text-lg leading-8">
						We will do our best to resolve any reported misconduct
						in a fair, respectful, and timely manner. Depending on
						the nature and severity of the misconduct, we may
						involve other parties, such as a mediator, a law
						enforcement agency, or a legal advisor. We will
						communicate with all parties involved to ensure that
						their rights and interests are protected.
					</p>
				</section>

				<section className="mb-16">
					<h2 className="py-4 text-2xl font-bold">Conclusion</h2>
					<p className="text-lg leading-8">
						We believe that a positive and respectful community is
						essential to achieving our mission of promoting and
						celebrating technology and innovation. By adhering to
						this Code of Conduct, we can create an environment that
						is welcoming, inclusive, and safe for everyone. Thank
						you for your commitment to making SOFTEC'23 a success
						and a positive experience for all.
					</p>
				</section>
			</main>
		</Layout>
	);
};

export default CodeOfConduct;
