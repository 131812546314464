const Campus = () => {
    return (
        <section className="flex flex-col items-center w-full my-20">
            <div className="flex justify-center w-full py-12 md:py-24 bg-fast">
                <div className="flex flex-col w-full md:flex-row">
                    <div className="flex flex-col w-full px-3 md:px-12 md:w-1/2">
                        <div className="text-3xl font-bold text-white md:text-6xl">
                            Campus
                        </div>
                        <p className="mt-8 font-sans text-sm font-bold text-white md:text-xl">
                            The Lahore Campus, spread over 12.5 acres, is
                            located in Faisal Town which is the heart of greater
                            Lahore. The campus consists of three blocks, which
                            with their striking exterior brickwork and
                            magnificent marble domes are a valuable addition to
                            the rich architectural heritage of Lahore.
                        </p>
                        <p className="mt-4 font-sans text-sm font-bold text-white md:text-xl">
                            The facilities consist of fully air-conditioned
                            purpose-built classrooms equipped with teaching
                            aids, and a number of computing and engineering
                            laboratories. The Engineering labs comprise
                            Electronics, Digital Systems, Embedded Controls,
                            Antenna and Microwave, Microprocessors, and
                            Communications Labs. The computing labs consist of
                            database, programming, software engineering, and
                            computer application labs.
                        </p>
                    </div>
                    <div className="flex justify-center w-full mt-12 md:w-1/2 md:mt-0">
                        <iframe
                            title="Map"
                            loading="lazy"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13607.668560810213!2d74.27661824911654!3d31.498961304052212!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391903f08ebc7e8b%3A0x47e934f4cd34790!2sFAST%20NUCES%20Lahore%20Campus!5e0!3m2!1sen!2s!4v1580080747006!5m2!1sen!2s"
                            width="750"
                            height="400"
                        ></iframe>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Campus;
