// import FadeSlideIn from "../Animations/FadeSlideIn";
import Button from "../../components/Button/Button";
import Input from "../Input/Input";

const memeberInfo = {
    name: "",
    email: "",
    cnic: "",
    phone: "",
};

const Competition = ({ form, setForm, competitions }) => {
    function handleNext(e) {
        e.preventDefault();
        const selectedCompetition = competitions.filter(
            (comp) => comp.id === +form.competitionId
        )[0];

        let newTeam = Array.from(
            { length: selectedCompetition?.max_team_size },
            () => ({ ...memeberInfo })
        );

        setForm({
            ...form,
            teamMembers: newTeam,
            step: form.step + 1,
            selectedCompetition: selectedCompetition,
        });
    }

    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };

    return (
        <form className="px-5 mx-auto max-w-[400px]" onSubmit={handleNext}>
            {/* <FadeSlideIn> */}
            <Input
                text="Competition"
                type="select"
                value={form.competitionId}
                name="competitionId"
                list={competitions}
                onChange={handleChange}
                required
            />

            {/* </FadeSlideIn> */}
            <div className="flex gap-6 mt-10">
                <Button small text="Next" />
            </div>
        </form>
    );
};

export default Competition;
