import RadioButton from "./RadioButton";
const RadioList = ({ list, selected, handleClick }) => {
    return (
        <>
            <ul className="flex md:flex-row flex-wrap justify-center gap-2 md:gap-4 my-2 z-[100]">
                {list?.map((entity) => {
                    return (
                        <li
                            key={entity.id}
                            className="rounded-2xl select-none w-[100px] md:w-[200px]"
                        >
                            <RadioButton
                                entity={entity}
                                selected={selected}
                                handleClick={handleClick}
                            />
                        </li>
                    );
                })}
            </ul>
            <hr />
        </>
    );
};

export default RadioList;
