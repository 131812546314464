import Title from "../Title/Title";
import Marquee from "react-fast-marquee";
import apiClient from "../../api/apiClient";
import { useQuery } from "react-query";
import Error from "../Error";
import Loader from "../Loader";

const CommunityPartners = () => {
    const {
        data: CommunityPartners,
        error,
        isLoading,
    } = useQuery(
        "communityPartners",
        async () => {
            const response = await apiClient.get("/community-partners/");
            return response.data;
        },
        {
            cacheTime: 300000, // 5 minutes
        }
    );

    if (error) {
        return <Error />;
    }

    if (CommunityPartners) {
        return (
            <section className="md:mt-20 md:w-full w-10/12 mx-auto mt-10">
                <Title text="Community Partners" />
                <Loader isLoading={isLoading}>
                    <Marquee
                        className="communityMarquee"
                        gradient
                        speed={150}
                        pauseOnClick
                        pauseOnHover
                    >
                        {CommunityPartners.map((community) => (
                            <a
                                key={community.id}
                                href={community.link}
                                target="_blank"
                                rel="noreferrer"
                                className="p-4 mx-5 cursor-pointer md:mx-10 sm:w-[200px] md:w-[300px]"
                            >
                                <img
                                    src={community.logo}
                                    alt={community.title}
                                    title={community.title}
                                    loading="lazy"
                                    className="max-w-[200px] object-cover p-0"
                                />
                            </a>
                        ))}
                    </Marquee>
                </Loader>
            </section>
        );
    }
};

export default CommunityPartners;
