import { useState } from "react";
import { toast } from "react-toastify";
import apiClient from "../../api/apiClient";
import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import Layout from "../../components/Layouts/Layout";
import Spacer from "../../components/Spacer/Spacer";
import Title from "../../components/Title/Title";
import useConfig from "../../utils/useConfig.jsx";
import { useQuery } from "react-query";
import Loader from "../../components/Loader.jsx";

const AmbassadorRegistration = () => {
    const [form, setForm] = useState({
        name: "",
        email: "",
        phone: "",
        university: "",
        discipline: "",
        batch: "",
        mailing_address: "",
    });
    const [loading, setLoading] = useState(false);

    const { isOpen, isLoading } = useConfig("ambassador");

    const { data: Batches } = useQuery(
        ["batches"],
        async () => {
            const result = await apiClient.get("batches/");
            return result.data;
        },
        {
            cacheTime: 3600000, // 1 hour
        }
    );

    const handleSubmit = (e) => {
        e.preventDefault();

        if (form.phone.length !== 11) {
            toast.error(
                "Please enter phone numnber in the format  as requested!"
            );
            return;
        }

        if (
            !(
                form.name &&
                form.email &&
                form.phone &&
                form.university &&
                form.discipline &&
                form.batch &&
                form.mailing_address
            )
        ) {
            toast.error("Please fill all the fields");
            return;
        }
        setLoading(true);
        apiClient
            .post("/registration/ambassador/", form)
            .then((res) => {
                toast.success(
                    "Registration Successful, Our team will get in contact with you soon!"
                );
                setForm({
                    name: "",
                    email: "",
                    phone: "",
                    university: "",
                    discipline: "",
                    batch: "",
                    mailing_address: "",
                });
            })
            .catch((err) => {
                toast.error("Registration Failed");
            })
            .finally(() => setLoading(false));
    };

    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };

    return (
        <Layout size={3} title="Ambassador Registration">
            <Title text="Ambassador Registration" />
            <Loader isLoading={isLoading}>
                {!isLoading && isOpen ? (
                    <form className="px-6" onSubmit={handleSubmit}>
                        <Input
                            text="Full Name"
                            value={form.name}
                            name="name"
                            placeholder="John Doe"
                            onChange={handleChange}
                            required
                        />

                        <Input
                            text="Email Address"
                            type="email"
                            name="email"
                            value={form.email}
                            placeholder="john@mail.com"
                            onChange={handleChange}
                            required
                        />

                        <Input
                            text="Phone Number"
                            value={form.phone}
                            name="phone"
                            placeholder="03001234567"
                            pattern="[0-9]{11}"
                            onChange={handleChange}
                            required
                        />

                        <Input
                            text="University"
                            value={form.university}
                            name="university"
                            placeholder="FAST NUCES, Lahore"
                            onChange={handleChange}
                            required
                        />

                        <Input
                            text="Discipline"
                            value={form.discipline}
                            name="discipline"
                            placeholder="BS Computer Science"
                            onChange={handleChange}
                            required
                        />

                        <Input
                            type="select"
                            text="Batch"
                            value={form.batch}
                            name="batch"
                            required
                            onChange={handleChange}
                            list={Batches || []}
                        />
                        <Input
                            text="Postal Address"
                            value={form.mailing_address}
                            name="mailing_address"
                            placeholder="House # 123, Street 12, Gulberg, Lahore"
                            onChange={handleChange}
                            required
                        />

                        <Button loading={loading} text="Register" />
                    </form>
                ) : (
                    <>
                        <h1 className="md:text-2xl mt-5 text-xl text-center text-red-500">
                            Registration is Closed!
                        </h1>
                        <Spacer height={300} />
                    </>
                )}
            </Loader>
        </Layout>
    );
};

export default AmbassadorRegistration;
