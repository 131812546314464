import { imagesArr } from "../../assets";
import Title from "../Title/Title";

let formatter = new Intl.NumberFormat("ur-PK", {
    style: "currency",
    currency: "PKR",
    minimumFractionDigits: 0,
}).format;

const CompetitionPrizes = ({ selected }) => {
    return (
        <section className="prizes">
            <Title underline={false} text="Prizes" />
            <div
                className={`flex flex-col items-center justify-center gap-10 px-6 py-4 mt-10 md:items-end md:flex-row`}
            >
                <div className="border text-white order-1 md:order-2 rounded-xl py-8 px-6 md:w-[26rem] bg-[#C9B037] shadow-2xl">
                    <img
                        src={imagesArr.GoldAward}
                        alt="Gold Award"
                        width={150}
                        className="pb-2 mx-auto"
                    />

                    <h3 className="text-7xl mt-4 font-bold text-center">
                        Winner
                    </h3>
                    <h4 className="mt-5 text-3xl text-center">
                        <strong>{formatter(selected.winner_Prize)}</strong>
                    </h4>
                </div>
                {selected.runnerUp_Prize && (
                    <div
                        className={`border text-white order-2 ${
                            selected.second_RunnerUp_Prize
                                ? "md:order-1"
                                : "md:order-3"
                        }  w-[16rem] rounded-xl py-5 px-4 md:w-[18rem] bg-[#B4B4B4] shadow-lg`}
                    >
                        <img
                            src={imagesArr.SilverAward}
                            alt="Silver Award"
                            width={100}
                            className="pb-2 mx-auto"
                        />
                        <h3 className="text-4xl font-bold text-center">
                            Runner up
                        </h3>
                        <h4 className="mt-5 text-2xl text-center">
                            <strong>
                                {formatter(selected.runnerUp_Prize)}
                            </strong>
                        </h4>
                    </div>
                )}

                {selected.second_RunnerUp_Prize && (
                    <div className="border text-white order-3 rounded-xl w-[16rem] py-5 px-4 md:w-[18rem] bg-[#b56a2f] shadow-lg">
                        <img
                            src={imagesArr.BronzeAward}
                            alt="Bronze Award"
                            width={75}
                            className=" pb-2 mx-auto"
                        />
                        <h3 className="text-3xl font-bold text-center">
                            Runner up 2
                        </h3>
                        <h4 className="mt-5 text-2xl text-center">
                            <strong>
                                {formatter(selected.second_RunnerUp_Prize)}
                            </strong>
                        </h4>
                    </div>
                )}
            </div>
            {selected?.name.includes("Gaming") && (
                <>
                    <Title underline={false} text="Valorant Prize" />
                    <div
                        className={`flex flex-col items-center justify-center gap-10 px-6 py-4 mt-10 md:items-end md:flex-row`}
                    >
                        <div className="border text-white order-1 md:order-2 rounded-xl py-8 px-6 md:w-[26rem] bg-[#C9B037] shadow-2xl">
                            <img
                                src={imagesArr.GoldAward}
                                alt="Gold Award"
                                width={150}
                                className="pb-2 mx-auto"
                            />

                            <h3 className="text-7xl mt-4 font-bold text-center">
                                Winner
                            </h3>
                            <h4 className="mt-5 text-3xl text-center">
                                <strong>{formatter(50000)}</strong>
                            </h4>
                        </div>
                        <div
                            className={`border text-white order-2 md:order-3 w-[16rem] rounded-xl py-5 px-4 md:w-[18rem] bg-[#B4B4B4] shadow-lg`}
                        >
                            <img
                                src={imagesArr.SilverAward}
                                alt="Silver Award"
                                width={100}
                                className="pb-2 mx-auto"
                            />
                            <h3 className="text-4xl font-bold text-center">
                                Runner up
                            </h3>
                            <h4 className="mt-5 text-2xl text-center">
                                <strong>{formatter(20000)}</strong>
                            </h4>
                        </div>
                    </div>
                </>
            )}
        </section>
    );
};

export default CompetitionPrizes;
