import SlideEffect from "../Animations/SlideEffect";
import Button from "../Button/Button";
import Input from "../Input/Input";

const TeamRegistration = ({ form, setForm }) => {
    function handleNext(e) {
        e.preventDefault();
        setForm({ ...form, step: form.step + 1 });
    }
    function handlePrev() {
        setForm({
            ...form,
            step: form.step - 1 >= 0 ? form.step - 1 : 0,
        });
    }

    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };

    return (
        <form className="px-5" onSubmit={handleNext}>
            <SlideEffect>
                <div className="pb-10">
                    {form.config.isAccommodationRegOpen && (
                        <h4 className="text-lg">
                            <span className="font-semibold">
                                Accommodation Fee:{" "}
                            </span>
                            PKR. {form.config.accommodationFee} per person
                        </h4>
                    )}
                    {form.config.sFoodRegOpen && (
                        <h4 className="text-lg">
                            <span className="font-semibold">Food Fee: </span>
                            PKR. {form.config.foodFee} per person
                        </h4>
                    )}
                </div>

                <div className="flex flex-col w-full">
                    <Input
                        text="Team Name"
                        placeholder="Team Name"
                        value={form.teamName}
                        name="teamName"
                        onChange={handleChange}
                        required
                    />

                    <Input
                        text="Institute"
                        placeholder="Institute"
                        value={form.institute}
                        name="institute"
                        onChange={handleChange}
                        required
                    />

                    <div className="flex">
                        {form.config.isFoodRegOpen && (
                            <Input
                                text="Food Count"
                                placeholder="Number of persons"
                                value={form.food}
                                min={0}
                                type="number"
                                max={form.selectedCompetition.max_team_size}
                                disabled={!form.config.isFoodRegOpen}
                                name="food"
                                onChange={handleChange}
                                tooltip="Enter the number of persons you require food for. This fee provides you 6 meals per person."
                            />
                        )}

                        {form.config.isAccommodationRegOpen && (
                            <Input
                                text="Accommodation"
                                type="number"
                                placeholder="Number of persons"
                                value={form.accommodation}
                                name="accommodation"
                                onChange={handleChange}
                                min={0}
                                max={form.selectedCompetition.max_team_size}
                                disabled={!form.config.isAccommodationRegOpen}
                                tooltip="Enter the number of persons you require accomodation(stay) for. This fee provides you stay at SOFTEC from Friday night to Sunday 6pm."
                            />
                        )}
                    </div>

                    <Input
                        text="Ambassador/Discount Code"
                        placeholder="SF0000"
                        value={form.ambassador}
                        name="ambassador"
                        onChange={handleChange}
                    />
                </div>
            </SlideEffect>
            <div className="md:flex-row flex flex-col-reverse gap-6 mt-10">
                <Button
                    small
                    text="Back"
                    outlined
                    type="button"
                    onClick={handlePrev}
                />
                <Button small text="Next" />
            </div>
        </form>
    );
};

export default TeamRegistration;
