const FeatureCard = ({ text, value, svg, bg }) => {
    const borderLeft = bg?.split(" ")[1];

    return (
        <div
            className={`flex flex-wrap gap-4 rounded-xl border-l-4 ${borderLeft}`}
        >
            <div className="flex items-start p-6 shadow-xl min-w-[350px] bg-gray-50">
                <div
                    className={`flex items-center justify-center mx-4 h-16 w-16 rounded-full border ${bg} `}
                >
                    <img src={svg} alt="feature icon" width={35} />
                </div>
                <div className="ml-4">
                    <h2 className="text-3xl font-bold">{value}</h2>
                    <p className="mt-2 text-base text-gray-500">{text}</p>
                </div>
            </div>
        </div>
    );
};

export default FeatureCard;
